import React from "react"
import Layout from "../components/utils/Layout/Layout"
import PoweredBy from "./../components/aboutComponents/PoweredBy/PoweredBy"
import Team from "../components/aboutComponents/Team/Team"
import { Seo } from "../components/seo"

const About = () => {
  return (
    <Layout>
      <PoweredBy />
      <Team />
    </Layout>
  )
}

export default About

export const Head = () => (
  <Seo title="Binary bioskop | O nama" pathname="about" />
)
